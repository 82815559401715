import React from 'react';
import DrawerAppBar from '../components/Header';
import logo from '../Images/logo.png'
import suv from '../Images/suv.jpg'
import sedan from '../Images/sedan.jpg'
import minicab from '../Images/minicab.png'
import SEO from '../components/SEO';



function HomePage() {
 

  return (
    <div >
      <SEO />
      <DrawerAppBar />
    <div className='App'>
      <div className=".banner">
        <section className="banner-home">
          <div className="banner-box">
            <h1>
              <span style={{color:"white",fontWeight:"bolder",fontFamily:"monospace"}}>RakeshCab  <span style={{color:"yellow",fontWeight:"bold"}}>Taxi</span> Indore <span style={{color:"yellow",fontWeight:"bolder"}}> Service Online & Offline</span></span>
               
            </h1>
          </div>
        </section>
      </div>
      <section className="search-section">
				<div className="container">
					<div className="tab-sec">
						<nav>
							<div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
								<a className="nav-item nav-link active" id="nav-stander-tab" data-toggle="tab" href="#nav-stander" role="tab" aria-controls="nav-stander" aria-selected="true">
									<img src={sedan} alt="Car12" loading='lazy' />
									<p>sedan</p>
								</a>								
								<a className="nav-item nav-link" id="nav-suv-tab" data-toggle="tab" href="#nav-suv" role="tab" aria-controls="nav-suv" aria-selected="false">
									<img src={suv} alt="Car2"
                  loading='lazy'
                  />								
									<p>suv</p>
								</a>								
								<a className="nav-item nav-link" id="nav-mini-tab" data-toggle="tab" href="#nav-mini" role="tab" aria-controls="nav-mini" aria-selected="false">
									<img loading='lazy' src={minicab} alt="Car" />								
									<p>mini</p>
								</a>								
								<a className="nav-item nav-link" id="nav-vip-tab" data-toggle="tab" href="#nav-vip" role="tab" aria-controls="nav-vip" aria-selected="false">
									<img loading='lazy' src={suv} alt="Car1" />								
									<p>vip</p>
								</a>
							</div>
						</nav>
						<form className="form-inline d-none">	
								<div className="form-group">
									<a href='https://forms.gle/uiBXu7MXjM3PUUF36' className="btn book-ride">Book a ride</a>
								</div>
						</form>
					</div>
				</div>
			</section>
      <footer>
	<div className="container">
		<div className="row">
			<div className="col-md-6 col-lg-4">
				<div className="logo-sec">
					<a href="index.html"><img loading='lazy' src={logo} width={"150px"} height={"150px"} alt="Footer1" /></a>
					<p>For outstation cab booking, Indore Taxi Indore offers the cheapest fare packages with expert drivers who could fully assist in the way of your destination. We will make available the best online outstation cab booking deals for a round trip taxi when visiting nearby. </p>
				</div>						
			</div>
			<div className="col-md-6 col-lg-4">
				<div className="services">
					<h3>Services</h3>
					<ul>
						<li><a href="index.php">Home</a></li>
						<li><a href="about.php">About Us</a></li>
						<li><a href="service.php">Service</a></li>
						<li><a href="gallery.php">Gallery</a></li>
						<li><a href="contact.php">Contact Us</a></li>						
					</ul>							
				</div>
			</div>
			<div className="col-md-6 col-lg-4">
				<div className="get-in-touch">
					<h3>Get In Touch</h3>
					<p><svg class="svg-inline--fa fa-map-marker-alt fa-w-12" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg> <i class="fas fa-map-marker-alt"></i> 359 &amp;, kalyan sampat vihar, Gandhi Nagar, Indore, 453112</p>
					<ul>
						<li><a href="tel:+918643019660"><svg class="svg-inline--fa fa-phone-alt fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg><i class="fas fa-phone-alt"></i> +91 8643019660 || 8120448976</a></li>
						<li><a href="mailto:indoretaxi264@gmail.com"><svg class="svg-inline--fa fa-envelope fa-w-16" aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg><i class="far fa-envelope"></i>info@rakeshcabtaxi.in</a></li>									
					</ul>
				</div>
			</div>				
		</div>
	</div>
	<div className="footer-bottom"><p>© 2024.  <a href="https://rakeshcabtaxi.in" class="main_link"> Rakeshcab taxi Indore</a>.  All Rights Reserved. | Design &amp; Developed By
	    <a href="https://rakeshcabtaxi.in" class="main_link"> Praveen Nayak</a>
	    </p>
	</div>
</footer>
    </div>
    </div>
  );
}

export default HomePage;
