import './App.css';
import HomePage from './pages/HomePage';
import React from 'react';
import withClearCache from "./ClearCache";
import {
  Route,
  Routes,
  BrowserRouter
} from "react-router-dom";
import AboutUsPage from './pages/AboutUsPage';

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return  <ClearCacheComponent />

}

function MainApp(props) {
  return (
      <BrowserRouter>
       <Routes>
       <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/booking" element={<HomePage />} />
        <Route path="/about" element={<AboutUsPage />} />
      </Routes>
      </BrowserRouter>
  );
}

export default App;
