import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = () => {
    return (
        <Helmet>
            <title>Rakesh Cab Taxi Service | Book Reliable Taxi in Indore</title>
            <meta name="description" content="Rakesh Cab offers reliable and affordable taxi services in Indore. Book now for airport transfers, local trips, and more." />
            <meta name="description" content="Rakesh Cab offers reliable and affordable taxi services in Ujjain. Book now for airport transfers, local trips, and more." />

        </Helmet>
    );
};

export default SEO;
